import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import Cover from '../assets/CVWriter_panel.png'
import SBoardRelatedCards from "./SBoardRelatedCards"
import SB_Footer from './SB_Description_Footer'

class SBCVWriterDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>CVWriter</h2>
                        <h3>Write CV into your DAW</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{maxHeight: '372px', width: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>CV to Automation module</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Modifier</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>CVWriter</p>
                                <div className={SBDStyle.currPrice}>US $19</div>
                            </div>          
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>Must use with S-Board!</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Overview</h2>
                    </div>
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Write CV into your DAW</h4>
                        <p>CVWriter takes CV inputs from LFO, EnvFollower, or any other modifiers, transfers the CV into automation data, and sends the data to your DAW.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Automate other plugins with S-Board modifiers</h4>
                        <p>Once the automation data is recorded in the DAW, you are free to copy and paste it anywhere to automate anything from track volume/pan to other plugins' parameters. </p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>4 channels of CV, perfectly in sync</h4>
                        <p>All 4 CV inputs are written to the DAW synchronously, with sample-wise timing precision. </p>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="cvwriter"/>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBCVWriterDescription